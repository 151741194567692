import React, { useMemo } from "react";
import ReactMarkdown from "react-markdown";
import { Typography, Box } from "@material-ui/core";
import Image from "gatsby-image";
import styled from "styled-components";
import { lighten } from "polished";
import Underlined from "../components/underlined-typography";
import AboutUsDatasource from "../datasources/about-us";
import BasePage from "../components/base-page";
import Layout from "../components/layout";

const TitleLogoWrapper = styled.div`
  position: relative;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`

const Title = styled(Typography)`
  display: inline;
`

const TitleLogo = styled(Image)`
  position: absolute !important;
  top: 0;
  left: 0;
`;

const TitleLogoTypography = styled(Typography)`
  text-indent: -9999px;
`;

const HeaderWrapper = styled(Box)`
  display: block;
`;

const HeaderContent = styled.div`
  display: inline-flex;
  flex-direction: column;
  padding: 0 1em 0.2em 0;
`;

const Subtitle = styled.div`
  font-size: 1em;
  font-weight: 700;
  color: ${props => lighten(.2, props.theme.palette.secondary.main)}
`;

const Logo = styled(Image)`
  margin-right: 1em;
`;

const Header = (props) => {
  const { node } = props;
  return (
    <HeaderWrapper mb={3}>
      <HeaderContent>
          <Subtitle>{node.childMarkdownRemark.frontmatter.subtitle}</Subtitle>
          {node.childMarkdownRemark.frontmatter.titleLogo ? (
            <TitleLogoWrapper>
              <TitleLogo alt={node.childMarkdownRemark.frontmatter.title} fixed={node.childMarkdownRemark.frontmatter.titleLogo.childImageSharp.fixed} style={{ position: "absolute" }} />
              <TitleLogoTypography variant="h4" component="h2">{node.childMarkdownRemark.frontmatter.title}</TitleLogoTypography>
            </TitleLogoWrapper>
          ) : (
            <TitleWrapper>
              <Logo alt={node.childMarkdownRemark.frontmatter.title} fixed={node.childMarkdownRemark.frontmatter.logo.childImageSharp.fixed} />
              <Title variant="h4" component="h2">{node.childMarkdownRemark.frontmatter.title}</Title>
            </TitleWrapper>
          )}        
      </HeaderContent>
    </HeaderWrapper>
  );
}

const AboutSection = (props) => {
  const { node } = props;
  return (
    <Box mb={3}>
      <Header node={node} />
      <ReactMarkdown source={node.childMarkdownRemark.rawMarkdownBody}></ReactMarkdown>
    </Box>
  );
};

export default function AboutPage(props) {
  const renderers = useMemo(() => {
    
    return {
      heading: ({ children }) => <Typography variant="h3" component="h1">{children}</Typography>,
    }
  }, []);

  return (
    <BasePage {...props}>
      <Layout>
        <Box mb={3}>
          <ReactMarkdown source={props.pageContext.content} renderers={renderers} />
        </Box>
        <AboutUsDatasource>
          { ({ nodes }) => nodes.map(node => (
            <AboutSection node={node} />
          ))} 
        </AboutUsDatasource>
      </Layout>
    </BasePage>
  )
}