import { useStaticQuery, graphql } from "gatsby"
import { useIntl } from "../intl"

const AboutUs = ({ children }) => {
  const intl = useIntl()
  const data = useStaticQuery(graphql`
    query AboutUsQuery {
      allFile(
        filter: { relativePath: { glob: "about/*.md" } }
        sort: { fields: name }
      ) {
        nodes {
          name
          id
          relativePath
          childMarkdownRemark {
            frontmatter {
              title
              subtitle
              logo {
                childImageSharp {
                  fixed(height: 50) {
                    ...GatsbyImageSharpFixed_noBase64
                  }
                }
              }
              titleLogo {
                childImageSharp {
                  fixed(height: 50) {
                    ...GatsbyImageSharpFixed_noBase64
                  }
                }
              }
            }
            rawMarkdownBody
          }
        }
      }
    }
  `)

  const filtered = data.allFile.nodes.filter(node => {
    const [, locale] = node.name.split(".")

    return locale === intl.locale
  })

  return children({
    ...data.allFile,
    nodes: filtered,
  })
}

export default AboutUs
